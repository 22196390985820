import "./src/assets/styles/global.css";
import "./src/assets/styles/animate.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./src/i18n";

import React from "react";
import GlobalContextProvider from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {
    return (
        <GlobalContextProvider>
            {element}
        </GlobalContextProvider>
    )
}