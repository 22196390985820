import React, { useEffect } from 'react';

const initialState = typeof window !== 'undefined' && window.localStorage ? JSON.parse(localStorage.getItem("globalState")) || {
    language: "es"
} : {
    language: "es"
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            return {...state, language: action.payload};
    }
}

export const GlobalStateContext = React.createContext(null);
export const GlobalDispatchContext = React.createContext(null);

const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.localStorage)
            localStorage.setItem("globalState", JSON.stringify(state));
    }, [state]);

    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export default GlobalContextProvider;